import { PaymentInformation } from '@hawaiianair/payment/lib/models/amadeus-payment.model'
import { Injectable } from '@angular/core';
import { Observable, retry, catchError, tap} from 'rxjs';
import { Response } from '../../../models/response.model';
import { BaseService } from '../base.service';
import { environment } from '~environments/environment';
import { HttpClient } from '@angular/common/http';
import { SessionSelectors } from '../session/session.selectors';
import { OrderStatus} from '../../../models/orderStatus.model'

export const TRANSACTIONID = "001";
export const NEW_ORDER_STATUS = "NEW";

@Injectable({
  providedIn: 'root'
})

export class OrderFulfillService extends BaseService {
  sessionConfirmationCode$: Observable<string>;
  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors
    ) {
    super(http, sessionSelectors);
    this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
  }

  getStatus(orderId: string): Observable<Response<OrderStatus>> {
    const URL = `${environment["exp-web-checkin-v2-api"]}/order/${orderId}/status`;
    return this.http.get<Response<OrderStatus>>(URL).pipe(
        tap(res => {
            if (res?.results[0]?.orderAttributes?.isComplete === true) {
                return res;
            } else {
                throw { error: 'order status not complete', requestData: res };
            }
        }),
        retry({ count: 90, delay: 2000 }),
        catchError(err => { throw err; })
    )
  };

  orderFulfill(cart: string, paymentInformation?: PaymentInformation): Observable<Response<{orderId: string}>> {
    const body = { cart, ...(paymentInformation && { paymentInformation }) };
    const url = `${environment["exp-web-checkin-v2-api"]}/order/fulfill`;
    return this.http.post<Response<{orderId: string}>>(url, body);
  };
}
