<section class="footer-section">
    <!-- Mobile View  Start-->
    <div id="webFooter-lt-sm" class="webFooter-lt-sm d-block d-sm-none" flex-container fxLayout.lt-md="column" fxShow="false" fxShow.lt-sm="true">
        <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <h4>{{ rendering.props['support'] }}</h4>
            </mat-expansion-panel-header>
            <div>
                <ul>
                    <li *ngIf="rendering.props['contactUsLink']">
                        <a *haLink="rendering.props['contactUsLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['bagFeesLink']">
                        <a *haLink="rendering.props['bagFeesLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['legalLink']">
                        <a *haLink="rendering.props['legalLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['helpCenterLink']">
                        <a *haLink="rendering.props['helpCenterLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['termsOfUseLink']">
                        <a *haLink="rendering.props['termsOfUseLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['sitemapLink']">
                        <a *haLink="rendering.props['sitemapLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['allFeesLink']">
                        <a *haLink="rendering.props['allFeesLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['guestWithDisabilitiesLink']">
                        <a *haLink="rendering.props['guestWithDisabilitiesLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['teamKokuaLink']">
                        <a *haLink="rendering.props['teamKokuaLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['travelAgentsLink']">
                        <a *haLink="rendering.props['travelAgentsLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['estaLink']">
                        <a *haLink="rendering.props['estaLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['fuelSurchargeLink']">
                        <a *haLink="rendering.props['fuelSurchargeLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['fareRulesLink']">
                        <a *haLink="rendering.props['fareRulesLink']"></a>
                    </li>
                </ul>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel >
            <mat-expansion-panel-header>
                <h4>{{ rendering.props['aboutUs'] }}</h4>
            </mat-expansion-panel-header>
            <div>
                <ul>
                    <li *ngIf="rendering.props['contractOfCarriageLink']">
                        <a *haLink="rendering.props['contractOfCarriageLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['customerServicePlanLink']">
                        <a *haLink="rendering.props['customerServicePlanLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['careersLink']">
                        <a *haLink="rendering.props['careersLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['tarmacDelayPlanLink']">
                        <a *haLink="rendering.props['tarmacDelayPlanLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['investorRelationsLink']">
                        <a *haLink="rendering.props['investorRelationsLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['privacyLink']">
                        <a *haLink="rendering.props['privacyLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['cargoLink']">
                        <a *haLink="rendering.props['cargoLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['shopOurStoreLink']">
                        <a *haLink="rendering.props['shopOurStoreLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['fareDealsLink']">
                        <a *haLink="rendering.props['fareDealsLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['giftCardLink']">
                        <a *haLink="rendering.props['giftCardLink']"></a>
                    </li>
                    <li *ngIf="rendering.props['damageReliefLink']">
                        <a *haLink="rendering.props['damageReliefLink']"></a>
                    </li>
                </ul>
            </div>
        </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="isCreditCardOfferAvailable() && false" id="credit-card-banner-lt-md" class = "cc-mobile-footer-banner-padding"
            fxShow="false"
            fxShow.lt-md="true">
            <div class="cc-mobile-footer-banner" >
                <div class="cc-mobile-footer-banner-lto">Limited-Time Offer</div>
                <div class="cc-mobile-footer-banner-bonus-miles">Earn 60,000 Bonus Miles</div>
                <div class="cc-mobile-footer-banner-annual-fee">Plus, a $0 intro annual fee</div>
                <div class = "cc-mobile-footer-banner-card-container">
                    <img src="/media/images/credit-card-offer/credit-card.jpg" class="cc-mobile-footer-banner-card" alt="Limited-Time Credit Card Offer"/>
                </div>
                <div>
                    <a href="https://www.barclaycardus.com/apply/Landing.action?campaignId=2653&cellNumber=13&referrerid=PTRBAFooterBan"
                    class="cc-mobile-footer-banner-apply" target="_blank" >Apply Now</a>
                </div>
                <div class="cc-footer-banner-cta-see-terms">
                        See <a href="//www.barclaycardus.com/apply/action/tncContent?prodidreq=CCMMX75584" target="_blank" style="color:#FFF">Terms & Conditions
                        </a> for details.
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isCreditCardOfferAvailable() && false" id="credit-card-banner-sm" class = "cc-mobile-footer-banner-padding d-none d-sm-block d-md-block d-lg-none"
        fxShow="false"
        fxShow.lt-md="true"
        fxShow.lt-sm="false">
        <div class="cc-mobile-footer-banner" >
            <div class="cc-mobile-footer-banner-lto">Limited-Time Offer</div>
            <div class="cc-mobile-footer-banner-bonus-miles">Earn 60,000 Bonus Miles</div>
            <div class="cc-mobile-footer-banner-annual-fee">Plus, a $0 intro annual fee</div>
            <div class = "cc-mobile-footer-banner-card-container">
                <img src="/media/images/credit-card-offer/credit-card.jpg" class="cc-mobile-footer-banner-card" alt="Limited-Time Credit Card Offer"/>
            </div>
            <div>
                <a href="https://www.barclaycardus.com/apply/Landing.action?campaignId=2653&cellNumber=13&referrerid=PTRBAFooterBan"
                class="cc-mobile-footer-banner-apply" target="_blank" >Apply Now</a>
            </div>
            <div class="cc-footer-banner-cta-see-terms">
                    See <a href="//www.barclaycardus.com/apply/action/tncContent?prodidreq=CCMMX75584" target="_blank" style="color:#FFF">Terms & Conditions
                    </a> for details.
            </div>
        </div>
    </div>
    <!-- Mobile View End-->

    <!-- Desktop View Start-->
    <div *ngIf="isCreditCardOfferAvailable() && false" id="credit-card-banner-gt-sm" class = "cc-footer-banner-padding d-none d-lg-block d-xl-block"
            fxShow="true" fxShow.lt-md="false">
        <div class="row cc-footer-banner" >
            <div class="col-md-12 mt-5">
                <div class="row">
                    <div fxFlex="30%" class="col-md-3 cc-footer-banner-card-container">
                        <img src="/media/images/credit-card-offer/credit-card.jpg" class="cc-footer-banner-card" alt="Limited-Time Credit Card Offer"/>
                    </div>
                    <div fxFlex="50%" class="col-md-6">
                        <div class="cc-footer-banner-lto">Limited-Time Offer</div>
                        <div class="cc-footer-banner-bonus-miles">Earn 60,000 Bonus Miles</div>
                        <div class="cc-footer-banner-annual-fee">Plus, a $0 intro annual fee</div>
                    </div>
                    <div fxFlex="20%" class="col-md-3 cc-footer-banner-apply-container">
                        <div class="row">
                            <div class="col mt-5 mr-5 text-right">
                                <a href="https://www.barclaycardus.com/apply/Landing.action?campaignId=2653&cellNumber=13&referrerid=PTRBAFooterBan"
                                    class="cc-footer-banner-apply" target="_blank" >Apply Now</a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right white">
                        See <a href="//www.barclaycardus.com/apply/action/tncContent?prodidreq=CCMMX75584" target="_blank" style="color:#FFF">Terms & Conditions
                        </a> for details.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="webFooter-gt-sm" class="webFooter-gt-sm d-none d-sm-block"
            flex-container fxLayout.lt-md="column" fxShow="true" fxShow.lt-sm="false" >
        <div class="row">
            <div fxFlex="50%" class="col-sm-6">
                <div class="row">
                    <h4 class="col-12 p-0" *haText="rendering.props['support']"></h4>
                    <ul>
                        <li *ngIf="rendering.props['contactUsLink']">
                            <a id="contact-us-link" *haLink="rendering.props['contactUsLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['bagFeesLink']">
                            <a id="bag-fees-Link" *haLink="rendering.props['bagFeesLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['helpCenterLink']">
                            <a id="help-center-link" *haLink="rendering.props['helpCenterLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['legalLink']">
                            <a id="legal-link" *haLink="rendering.props['legalLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['fareRulesLink']">
                            <a id="fare-rules-link" *haLink="rendering.props['fareRulesLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['allFeesLink']">
                            <a id="all-fees-link" *haLink="rendering.props['allFeesLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['termsOfUseLink']">
                            <a id="terms-of-use-link" *haLink="rendering.props['termsOfUseLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['teamKokuaLink']">
                            <a id="team-kokua-link" *haLink="rendering.props['teamKokuaLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['guestWithDisabilitiesLink']">
                            <a id="guests-with-disabilities-link" *haLink="rendering.props['guestWithDisabilitiesLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['travelAgentsLink']">
                            <a id="travel-agents-link" *haLink="rendering.props['travelAgentsLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['estaLink']">
                            <a id="esta-link" *haLink="rendering.props['estaLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['fuelSurchargeLink']">
                            <a id="fuel-surcharge-link" *haLink="rendering.props['fuelSurchargeLink']"></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div fxFlex="50%" class="col-sm-6">
                <div class="row">
                    <h4 class="col-12 p-0" *haText="rendering.props['aboutUs']"></h4>
                    <ul>
                        <li *ngIf="rendering.props['contractOfCarriageLink']">
                            <a id="contract-of-carriage-link" *haLink="rendering.props['contractOfCarriageLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['customerServicePlanLink']">
                            <a id="customer-service-plan-link" *haLink="rendering.props['customerServicePlanLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['careersLink']">
                            <a id="careers-link" *haLink="rendering.props['careersLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['tarmacDelayPlanLink']">
                            <a id="tarmac-delay-plan-link" *haLink="rendering.props['tarmacDelayPlanLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['investorRelationsLink']">
                            <a id="investor-relations-link" *haLink="rendering.props['investorRelationsLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['privacyLink']">
                            <a id="privacy-link" *haLink="rendering.props['privacyLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['cargoLink']">
                            <a id="cargo-link" *haLink="rendering.props['cargoLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['shopOurStoreLink']">
                            <a id="shop-our-store-link" *haLink="rendering.props['shopOurStoreLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['giftCardLink']">
                            <a id="gift-card-link" *haLink="rendering.props['giftCardLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['fareDealsLink']">
                            <a id="fare-deals-link" *haLink="rendering.props['fareDealsLink']"></a>
                        </li>
                        <li *ngIf="rendering.props['damageReliefLink']">
                            <a id="damage-relief-link" *haLink="rendering.props['damageReliefLink']"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Desktop View End-->

    <div class="logoGroup">
        <div class="haLogo"></div>
        <div class="socialLinks" flex-container fxLayout="row" fxLayoutAlign="center">
            <ng-container *ngFor="let social of rendering?.props['socialLinks']">
                <a id="{{ social.id }}-social-icon" href="{{ social.href }}" class="btnSocial {{ social.icon }}"
                    [attr.aria-label]="social.label"></a>
            </ng-container>
        </div>
    </div>
    <div class="text-center">
        <h4 id="copyright-text" class="copyright">
            &copy; {{year}}
            <span *haRichText="rendering.props['copyright']"></span>
        </h4>
    </div>
</section>