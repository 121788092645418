import { Action, createReducer, on } from '@ngrx/store';
import { Catalog } from '../../../models/catalog.model';
import { Response } from '../../../models/response.model';
import * as CatalogActions from '../../actions/catalog.actions';
import { CATALOG_ID_SEAT_AMADEUS } from '~app/store/services';

export interface CatalogState {
    catalog: Response<Catalog>;
    loading: boolean;
    bags: Catalog;
    seats: Catalog;
    correlationId?: string;
    error: boolean;
}

export const initialState: CatalogState = {
    catalog: null,
    loading: false,
    bags: null,
    seats: null,
    correlationId: '',
    error: false,
};

export const catalogReducer = createReducer(
    initialState,
    on(CatalogActions.bagsGetCatalog, state => ({...state, catalog: null, bags: null, loading: true, error: false})),
    on(CatalogActions.seatsGetCatalog, state => ({...state, catalog: null, bags: null, loading: true, error: false})),
    on(CatalogActions.itineraryGetCatalog, state => ({ ...state, catalog: null, bags: null, loading: true, error: false })),
    on(CatalogActions.dashboardGetCatalog, state => ({...state, catalog: null, bags: null, loading: true, error: false})),
    on(CatalogActions.getCatalogError, state => ({...state, loading: false, error: true})),
    on(CatalogActions.getCatalogSuccess, (state, { catalog }) => ({
        ...state,
        loading: false,
        catalog,
        bags: catalog.results.find(result => result.id === 'Bags'),
        seats: catalog.results.find(result => result.id === CATALOG_ID_SEAT_AMADEUS),
        correlationId: catalog.correlationId
    })),
    on(CatalogActions.initCatalogState, _ => (
        {
            ...initialState,
        }
    ))
);

export function reducer(state: CatalogState | undefined, action: Action) {
    return catalogReducer(state, action);
}
