import { CheckoutTransaction } from './../../../models/checkouttransaction.model';
import { PaymentItem } from './../../../models/paymentitem.model';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../../../models/response.model';
import { PartialSuccess } from './../../../models/partialsuccess.model';
import { Cart, Cart1A } from '../../../models/cart.model';
import { CartItem } from '~app/models/cartitem.model';
import { BaseService } from '../base.service';
import { environment } from '~environments/environment';
import { HttpClient } from '@angular/common/http';
import { PointOfSale } from '~app/models/pointofsale.model';
import { SessionSelectors } from '../session/session.selectors';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { CartSelectors } from './cart.selectors';

export const CART_STATE_CREATED = 'CREATED';
export const CART_STATE_PROCESSING = 'PROCESSING';
export const CATALOG_ID_SEAT_AMADEUS = 'Seats';
export const CATALOG_ID_BAG = 'Bags';
export const RESP_TYPE_SUCCESS = 'HA.Success';
export const RESP_TYPE_PARTIAL_SUCCESS = 'HA.PartialSuccess';
export const DEFAULT_TRANSACTIONID = "001";
export const NEW_STATUS = "NEW";
export const CREATED = "CREATED";
export const bagsIdentifiers = {
  regularBag: {
    id: '0GO',
  },
  freeItem: {
    id: '0DF'
  },
  oversized: {
    id: '0GP'
  },
  overweight: {
    id: '0FM'
  }
};

export interface ICartService {
  get(id: string): Observable<Response<Cart>>;
  create(segmentId: string, passengerId: string): Observable<Response<Cart>>;
  delete(id: string): Observable<any>;
  update(cartId: string, cartItems: CartItem[]): Observable<Response<Cart>>;
  // delete the existing one,then create a new.
  recreate(id: string, confirmationCode: string, segmentId: string, passengerId: string): Observable<Response<Cart>>;
}

@Injectable({
  providedIn: 'root'
})

export class CartService extends BaseService implements ICartService {
  private authenticationString = '';
  sessionConfirmationCode$: Observable<string>;
  session$: Observable<ISessionState>;
  cart$: Observable<Response<Cart>>;

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors,
    private cartSelectors: CartSelectors,
  ) {
    super(http, sessionSelectors);
    this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
    this.session$ = sessionSelectors.session$;
    this.cart$ = this.cartSelectors.cart$;
  }

  get(id: string): Observable<Response<Cart>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const url = `${environment["exp-web-checkin-v2-api"]}/carts/${id}${this.authenticationString}`;
    return this.http.get<Cart1A>(url, { observe: 'response' }).pipe(
      map((cart) => {
        return {
          status: "Success",
          count: [cart]?.length || 0,
          results: [{
            ...cart.body,
            items: [...cart.body.items?.seats || [], ...this.create1ABagsList(cart.body?.items?.bags)],
            totalPrices: [
              {
                catalogId: CATALOG_ID_BAG,
                price: (cart.body.items?.bags || [])?.reduce((sum, currBags) => sum + currBags?.price, 0)
              },
              {
                catalogId: CATALOG_ID_SEAT_AMADEUS,
                price: (cart.body.items?.seats || [])?.reduce((sum, currSeat) => sum + currSeat?.price, 0)
              }
            ]
          }]
        }
      })
    );

  }

  create(segmentId: string, passengerId: string): Observable<Response<Cart>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const params = `${this.authenticationString}&segmentId=${segmentId}&passengerIds=${passengerId}`;
    const url = `${environment["exp-web-checkin-v2-api"]}/carts${params}`;

    let tripInfo: { passengerIds: string[], journeyId: string, confirmationCode: string };
    this.session$.pipe(take(1)).subscribe(session =>
      tripInfo = { passengerIds: session?.passengerIds, journeyId: session?.selectedSegmentId, confirmationCode: session?.code }
    );
    const cart = {
      state: "CREATED",
      channel: "WEB",
      type: "Cart.Amadeus.CheckIn",
      version: 1,
      confirmationCode: tripInfo?.confirmationCode,
      passengers: tripInfo?.passengerIds,
      journeyId: tripInfo?.journeyId,
    };
    return this.http.post<Cart1A>(url, cart, { observe: 'response' }).pipe(
      map((cart) => {
        return {
          status: "Success",
          count: [cart]?.length || 0,
          results: [{
            ...cart.body,
            items: [...cart.body.items?.seats || [], ...this.create1ABagsList(cart.body?.items?.bags)],
          }]
        }
      })
    );

  };

  delete(id: string): Observable<any> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const url = `${environment["exp-web-checkin-v2-api"]}/carts/${id}${this.authenticationString}`;
    return this.http.delete(url);
  }

  update(cartId: string, cartItems: CartItem[]): Observable<Response<Cart>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const url = `${environment["exp-web-checkin-v2-api"]}/carts/${cartId}/items${this.authenticationString}`;
    let cartInfo: Cart;
    let journeyId: string;
    this.cart$.pipe(take(1)).subscribe(stateOfCart => cartInfo = { ...stateOfCart?.results?.[0] });
    this.session$.pipe(take(1)).subscribe(session =>
      journeyId = session?.selectedSegmentId
    );
    const cart = {
      state: cartInfo.state,
      channel: cartInfo.channel,
      type: cartInfo.type,
      grandTotal: cartInfo.grandTotal ?? 0,
      version: cartInfo.version,
      id: cartId,
      confirmationCode: cartInfo.confirmationCode,
      journeyId: journeyId,
      passengers: cartInfo.passengers,
      items: {
        ...(cartItems.filter(cartItem => { return cartItem.seat }).length && { seats: cartItems.filter(cartItem => { return cartItem.seat }) }),
        ...(cartItems.filter(cartItem => { return cartItem.catalogId === CATALOG_ID_BAG }) && { bags: cartItems.filter(cartItem => { return cartItem.catalogId === CATALOG_ID_BAG }) }),
      }
    };
    return this.http.put<Cart1A>(url, cart, { observe: 'response' }).pipe(
      map((cart) => {
        return {
          status: "Success",
          count: [cart]?.length || 0,
          results: [{
            ...cart.body,
            items: [...cart.body.items?.seats || [], ...this.create1ABagsList(cart?.body?.items?.bags)],
            totalPrices: [
              {
                catalogId: CATALOG_ID_BAG,
                price: (cart.body.items?.bags || [])?.reduce((sum, currBags) => sum + currBags?.price, 0)
              },
              {
                catalogId: CATALOG_ID_SEAT_AMADEUS,
                price: (cart.body.items?.seats || [])?.reduce((sum, currSeat) => sum + (Number.isFinite(currSeat?.priceDifference) ? currSeat.priceDifference : currSeat?.price), 0)
              }
            ]
          }]
        }
      })
    );
  }

  recreate(id: string, segmentId: string, passengerId: string): Observable<Response<Cart>> {
    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const url = `${environment["exp-web-checkin-v2-api"]}/carts/${id}${this.authenticationString}`;
    return this.http.delete(url).pipe(
      switchMap(
        () => {
          return this.create(segmentId, passengerId);
        }
      )
    );
  }

  checkout(
    confirmationCode: string,
    cartId: string,
    paymentItem: PaymentItem = null,
    pointOfSale: PointOfSale
  ): Observable<Response<PartialSuccess>> {
    const transaction: CheckoutTransaction = {
      confirmationCode: confirmationCode,
      checkoutTransactionId: DEFAULT_TRANSACTIONID,
      status: NEW_STATUS,
      payments: []
    };

    if (!!paymentItem) {
      transaction.payments?.push(paymentItem);
    }

    if (!!pointOfSale) {
      transaction.pointOfSale = pointOfSale;
    }

    this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
    const url = `${environment["exp-web-checkin-v2-api"]}/carts/${cartId}/checkout${this.authenticationString}`;
    return this.http.post<Response<PartialSuccess>>(url, transaction);
  }

  private create1ABagsList(bags: CartItem[]): CartItem[] {
    if (!bags) {
      return [];
    }
    return bags.reduce((newItems: CartItem[], bag) => {
      const bagIdx = newItems.findIndex(bagObj => bagObj?.productId === bag?.productId
        && bagObj.associatedPassenger?.journeyId === bag.associatedPassenger?.journeyId
        && bagObj.associatedPassenger?.passengerId === bag.associatedPassenger?.passengerId
        && bagObj.associatedPassenger?.segmentIds?.toString() === bag.associatedPassenger?.segmentIds?.toString()
        && bagObj.associatedPassenger?.passengerSegmentIds?.toString() === bag.associatedPassenger?.passengerSegmentIds?.toString());
      if (bagIdx !== -1) {
        newItems[bagIdx] = {
          ...newItems[bagIdx],
          ...bag,
          quantity: newItems[bagIdx]?.quantity + bag.quantity,
          price: newItems[bagIdx]?.price + bag.price
        }
      } else {
        newItems.push(bag);
      }
      return newItems;
    }, []);
  }
}
