import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { HelperService } from '~app/services/helper.service';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { SessionSelectors, SessionDispatchers, FlightSelectors, FlightDispatchers } from '~app/store';
import { FlightState } from '~app/store/reducers/flight/flight.reducers';
import { Flight } from '~app/models/flight.model';
import bwipjs from 'bwip-js';
import { Constants } from '~app/constants/ha-constants';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { AnalyticsService } from '@hawaiianair/analytics';

@Component({
  selector: 'app-mobile-boarding-pass',
  templateUrl: './mobile-boarding-pass.component.html',
  styleUrls: ['./mobile-boarding-pass.component.scss'],
})
@ContentSchema({
  name: 'MobileBoardingPass',
  description: 'Mobile Boarding Pass Component',
  props: [
    { name: 'operatedByOhana', type: PropertyType.Text },
    { name: 'zones', type: PropertyType.Object },
    { name: 'boardingPass', type: PropertyType.Text },
    { name: 'nrpsboardingPass', type: PropertyType.Text },
    { name: 'nrsaboardingPass', type: PropertyType.Text },
    { name: 'flight', type: PropertyType.Text },
    { name: 'boarding', type: PropertyType.Text },
    { name: 'seeGateAgent', type: PropertyType.Text },
    { name: 'boardingPassInfant', type: PropertyType.Text },
    { name: 'connectionCard', type: PropertyType.Text },
    { name: 'connectionCardInfant', type: PropertyType.Text },
    { name: 'notAvailable', type: PropertyType.Text },
    { name: 'eTicket', type: PropertyType.Text },
    { name: 'confirmationCode', type: PropertyType.Text },
    { name: 'baggageMessage', type: PropertyType.Text },
    { name: 'baggageMessageInternational', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItems', type: PropertyType.RichText },
    { name: 'boardingPassWithoutCheckinItemsInternational', type: PropertyType.RichText },
    { name: 'boardingPassWithCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItems', type: PropertyType.RichText },
    { name: 'boardingPassWithCheckinItemsInternational', type: PropertyType.RichText },
    { name: 'subjectToChange', type: PropertyType.Text },
    { name: 'name', type: PropertyType.Text },
    { name: 'date', type: PropertyType.Text },
    { name: 'flightInfo', type: PropertyType.Text },
    { name: 'eTicketInfo', type: PropertyType.Text },
    { name: 'departs', type: PropertyType.Text },
    { name: 'stop', type: PropertyType.Text },
    { name: 'arrives', type: PropertyType.Text },
    { name: 'confirmation', type: PropertyType.Text },
    { name: 'loyalty', type: PropertyType.Text },
    { name: 'bagCountBagTag', type: PropertyType.Text },
    { name: 'boardingStart', type: PropertyType.Text },
    { name: 'gateInfo', type: PropertyType.Text },
    { name: 'seatInfo', type: PropertyType.Text },
    { name: 'zoneInfo', type: PropertyType.Text },
    { name: 'boardingCloses', type: PropertyType.Text },
    { name: 'operatedBy', type: PropertyType.Text },
    { name: 'soldAs', type: PropertyType.Text },
    { name: 'nextStepsTitle', type: PropertyType.Text },
    { name: 'seq', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsOa', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItemsOa', type: PropertyType.Text },
    { name: 'tsaSecurityCheckpoint', type: PropertyType.Text },
    { name: 'noticeTitleDesktop', type: PropertyType.Text },
    { name: 'noticeDetails', type: PropertyType.RichText },
    { name: 'flightOperatedBy', type: PropertyType.Text },
    { name: 'notValidForBoardingDesktop', type: PropertyType.Text },
    { name: 'seatInf', type: PropertyType.Text },
    { name: 'mobileNoticePrompt', type: PropertyType.Text },
    { name: 'checkInAtOACounter', type: PropertyType.Text },
    { name: 'upgradeList', type: PropertyType.Text },
    { name: 'standbyList', type: PropertyType.Text },
    { name: 'anotherAirline', type: PropertyType.Text },
    { name: 'noticeOperatedBy', type: PropertyType.Text },
    { name: 'nonRevNotice', type: PropertyType.Text },
    { name: 'customerLevel', type: PropertyType.Object },
    { name: 'mainCabinBasicClass', type: PropertyType.Text },
    { name: 'prioritySeat', type: PropertyType.Text },
    { name: 'mobileWalletAds', type: PropertyType.Object },
  ]
})
export class MobileBoardingPassComponent implements OnInit {
  @Input() trip: any;
  @Input() rendering: ComponentRendering;
  resultData: Response<Trip>;
  index: number;
  flightDetails: any;
  flightsData: Flight[];
  boardingPassData: any;
  haveBags: boolean;
  flightState$: Observable<FlightState>;
  flightLoading$: Observable<boolean>;
  resultData$: Observable<Response<Trip>>;
  osDevice$: Observable<string>;
  // eslint-disable-next-line max-len
  BP_TSA_PRE_LOGO = "/media/images/boarding-pass/tsaprecheck_transparent.png";

  constructor(private helperService: HelperService,
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private flightSelectors: FlightSelectors,
    private flightDispatchers: FlightDispatchers,
    private dynamicContentService: DynamicContentService,
    private analyticsService: AnalyticsService
  ) {
    this.resultData$ = this.sessionSelectors.sessionResultData$;
    this.flightState$ = this.flightSelectors.flightState$;
    this.flightLoading$ = this.flightSelectors.flightLoading$;
    this.osDevice$ = this.sessionSelectors.osDevice$;
  }

  ngOnInit() {
    this.resultData$.pipe(take(1)).subscribe(resultData =>
      (resultData)
        ? this.resultData = resultData
        : this.sessionDispatchers.getSessionResultData()
    );

    this.flightDispatchers.boardingPassGetFlightsById();
    this.flightState$.subscribe(
      flightState => {
        if (flightState?.allFlights || flightState.error) {
          this.flightsData = flightState.allFlights ? flightState.allFlights : this.getFlightsFromTrip();
          if (!!this.flightsData) {
            this.trip =  this.updateTrips();
            this.haveBags = this.trip.bags.length > 0;
            if (!!this.trip) {
              this.setMobileBoardingPassData();
            }
          }
        }
      });

  }

  getFlightsFromTrip() {
    return [{
      'id': this.trip.flightId,
      'flightNumber': this.trip.flightNumber,
      'flightType': '',
      'aircraftType': this.trip.flightType,
      'airlineCode': this.trip.airlineCode,
      'origin': this.trip.origin,
      'scheduledDestination': this.trip.scheduledDestination,
      'scheduledDeparture': this.trip.scheduledDeparture,
      'scheduledArrival': this.trip.scheduledArrival,
      'scheduledBoardingTime': null,
      'seatMapCode': this.trip.aircraftType,
      'checkInStatus': this.trip.checkInStatus,
      'operatedBy': this.trip.operatedBy,
      'operatedByDescription': this.trip.operatedByDescription,
      'marketedBy': this.trip.marketedBy,
      'disclosureText': '',
      'status': null,
      'originLongName': this.trip.airportOriginLongName,
    }];
  }

  updateTrips(): any {
    const isOAWithCard = this.isOAWithConnectionCard(this.trip);
    return {
      ...this.trip,
      "boardingZone": this.trip?.boardingZone?.replace('Zone ', ''),
      "boardingPassType": this.getBPType(isOAWithCard),
      "originFullName": this.getFlightLocation(this.trip.origin),
      "destinationFullName": this.getFlightLocation(this.trip.scheduledDestination),
      "fullFlightNumber": this.trip.marketedBy !== Constants.operatedByHA && this.trip.operatedBy === Constants.operatedByHA ?
        this.getFlightNumber(this.trip?.operatingFlightNumber, this.trip.operatedBy) :
        this.getFlightNumber(this.trip.flightNumber, this.trip.airlineCode),
      "boardingTime": this.getBoardingTime(this.trip.flightId),
      "gateInfo": this.getGate(this.trip.flightId),
      "passengerFullName": this.getName(this.trip),
      "forConnectionCard": isOAWithCard,
      "loungeStatus": this.getLoungeStatus(),
      "OAText": this.getOaDisclosureText(isOAWithCard),
      "checkInAtOACounter": this.dynamicContentService.getContentString(
        this.rendering.props['checkInAtOACounter'], {otherAirline: this.getOaDisclosureText(isOAWithCard)}),
      "seatClassType": this.getSeatCategory(this.trip.seatCategory)
    };
  }

  setMobileBoardingPassData() {
    try {
      if (this.trip && !!this.trip.boardingPassData) {
        const canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
          bcid: 'azteccode',
          text: this.trip.boardingPassData
        });
        this.boardingPassData = canvas.toDataURL('image/png');
    }
    } catch (e) {
      console.log(e);
    }
  }

  isOAWithConnectionCard(trip: any) {
    if (trip.operatedBy !== Constants.operatedByHA) {
      if (trip.isCheckedIn === false) {
        return true;
      } else {
        if (trip.boardingPassData === null) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  getSeatCategory(seatClassType: string) {
    if (!!seatClassType && (seatClassType === Constants.seatClassTypeExtraComfort ||
      seatClassType === Constants.seatClassTypeFirstClass)) {
        return this.rendering.props["prioritySeat"];
    }
    return "";
  }

  getName(trip): string {
    return `${trip.passengerName.firstName} ${trip.passengerName.lastName}`;
  }

  getFlightNumber(number: string, code: string): string {
    return code + " " + parseInt(number, 10);
  }

  getFlightLocation(location: string): string {
    return this.getAirportName(location).split(',')[0];
  }

  getAirportName(airportCode: string): string {
    return this.helperService.getAirportName(airportCode);
  }

  getBoardingTime(flightId: string): string {
    const flight = this.flightsData?.find(flight => flight?.id === flightId);
    const entry = this.resultData?.results?.[0]?.flights?.entries?.find(flight => flight?.id === flightId);
    return flight?.status?.boardingStartTime?.airportDateTimeString ?? entry?.scheduledBoardingTime?.airportDateTimeString;
  }

  getBPType(isOAWithCard: boolean) {
    if (!this.trip) {
      return;
    }
    if (this.trip.isPositiveSpace) {
      return this.rendering.props['nrpsboardingPass'];
    } else if (this.trip.isPassTravel) {
      return this.rendering.props['nrsaboardingPass'];
    }
    else if (isOAWithCard) {
      if (this.trip.isInfant) {
        return this.rendering.props['connectionCardInfant'];
      } else {
        return this.rendering.props['connectionCard'];
      }
    } else {
      if (this.trip.isInfant) {
        return this.rendering.props['boardingPassInfant'];
      } else {
        return this.rendering.props['boardingPass'];
      }
    }
  }

  getGate(flightId: string) {
    if (!!this.flightsData) {
      const theFlight = this.flightsData.find(flight => flight.id === flightId);
      if (!!theFlight) {
        if (!!theFlight.status && !!theFlight.status.originGate) {
          return theFlight.status.originGate;
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }

  getLoungeStatus() {
    if (!this.trip) { return }

    if (this.trip.lounge !== 'None' && this.trip.customerLevel) {
      const memberStatus = this.getMemberStatus(this.trip.customerLevel);
      return `${memberStatus}/${this.trip.lounge}`;
    }

    if (this.trip.lounge !== 'None') {
      return this.trip.lounge;
    }

    if (this.trip.customerLevel) {
      return this.getMemberStatus(this.trip.customerLevel);
    }

    return null;
  }

  getMemberStatus(customerLevel: string) {
    const customerLevelUpper = customerLevel?.toUpperCase();
    if (customerLevel && ![Constants.customerLevelBasic, Constants.customerLevelMemb, Constants.customerLevelJpcc, Constants.customerLevelHacc, 'NONE'].includes(customerLevelUpper)) {
      const customerTier = this.rendering?.props?.customerLevel?.[customerLevelUpper];
      return customerTier ? customerTier : customerLevel;
    }

    return '';
  }

  getOaDisclosureText(isOAWithCard: boolean) {
    if (this.trip) {
      return (isOAWithCard && !this.trip.operatedByDescription) ? this.rendering.props["anotherAirline"] : this.trip.operatedByDescription;
    } else {
      return null;
    }
  }

  logLinkClick() {
    let deviceType;
    this.osDevice$.pipe(take(1)).subscribe(osDevice => deviceType = osDevice);
    this.analyticsService.logEvent(`[Boarding Pass] Boarding Pass Clicked - ${deviceType === 'iOS' ? 'Apple' : 'Google'}`);
  }
}
