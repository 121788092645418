<app-checkin-header id="seats-header" [heading]="rendering.props['heading']"
  [hideHeaderInfo]="(catalogLoading$ | async) || (tripLoading$ | async) || (cartLoading$ | async) || (securityInfoLoading$ | async)"></app-checkin-header>
<div *ngIf="(catalogLoading$ | async) || (cartLoading$ | async) || (tripLoading$ | async) || (securityInfoLoading$ | async); else seatPage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #seatPage>
  <div class="container" id="main-seat-wrapper">
    <div *ngIf="!!sessionData.trip2Checkin && sessionData.selectedSegment">
      <mat-tab-group mat-stretch-tabs [ngClass]="{'hidden-tab': flights2Checkin?.length === 1 }" (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngFor="let leg of flights2Checkin; index as legIdx">
          <ng-template mat-tab-label>
            <div id="seats-flight-{{legIdx}}-route" class="body-copy">
              {{leg.origin}}&nbsp;
              <i class="ha-icon icon-angle-right"></i>&nbsp;
              {{leg.scheduledDestination}}
            </div>
          </ng-template>
          <div class="seats-itin-body">
            <ng-container *ngFor="let pax of pax2Checkin; index as paxIdx">
              <mat-card *ngIf="leg.id === pax.legId">
                <div class="container">
                  <div class="row">
                    <div id="seats-passenger-{{paxIdx}}" class="col align-self-center text-left">
                      <span id="seats-name" class="subtitle-bold">
                        {{ pax.passengerName.firstName | titlecase }} {{ pax.passengerName.lastName | titlecase }}
                      </span>
                      <span *ngIf='!!pax?.lapInfant' id="seats-infant-name" class="col-md pl-2 subtitle-bold">
                        (+{{ pax.lapInfant.infantName.firstName | titlecase}} {{ pax.lapInfant.infantName.lastName | titlecase }})
                      </span>
                      <div class="d-flex" *ngIf="pax.isAddedToUpgradeList">
                        <i id="icon-check" class="ha-icon icon-check mr-2"></i><span id="added-to-upgrade-list-text"
                          *haText="rendering.props['addedToUpgradeList']" class="small secondary-text"></span>
                      </div>
                    </div>
                    <div *ngIf="pax.seatAssignment || leg.operatedBy !== codeHA; else elseBlock"
                      class="col-auto col-md-3 col-lg-2 text-right text-md-center">
                      <span *ngIf="leg.operatedBy === codeHA; else oaLeg" id="seats-assignment-HA-flight-{{paxIdx}}"
                        class="subtitle-bold aloha-pink">
                        {{ pax.seatAssignment }}
                      </span>
                      <ng-template #oaLeg>
                        <span id="oa-dashes" class="subtitle-bold"> -- </span>
                      </ng-template>
                      <div *ngIf="leg.operatedBy === codeHA" id="seats-category-desktop-{{paxIdx}}"
                        class="d-none d-md-block small secondary-text text-nowrap">
                        {{ pax.seatType}} {{pax?.seatType && pax?.seatCategoryType ? ", " : '' }} {{
                        pax?.seatCategoryType }}
                      </div>
                    </div>
                    <div *ngIf="leg.operatedBy === codeHA" id="seats-category-mobile-{{paxIdx}}"
                      class="container d-block d-md-none text-right small secondary-text">
                      {{ pax?.seatType }} {{pax?.seatType && pax?.seatCategoryType ? ", " : '' }} {{
                      pax?.seatCategoryType }}
                    </div>
                    <ng-template #elseBlock>
                      <div class="col-auto col-md-3 col-lg-2 text-right text-md-center">
                        <div id="dashes" class="subtitle-bold"> -- </div>
                        <div id="seat-to-be-assigned" class="small secondary-text"
                          *haText="rendering.props['toBeAssigned']"></div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </mat-card>
            </ng-container>
            <div class="container">
              <div class="seat-upsell-grid row d-flex d-md-none mt-5">
                <app-seat-upsell-grid class="col-md-12" [rendering]="seatUpsellRendering"
                  [flightId]="leg?.id"></app-seat-upsell-grid>
              </div>
              <ng-template [cdkPortalOutlet]="!isTablet ? seatTout : null"></ng-template>
              <div class="action row d-flex" *ngIf="flightsIsOperatedByHA[legIdx].operatedByHA">
                <div class="seat-message step-desc col-12 col-md-5 d-md-flex d-none"
                  *ngIf="!isNonRev && pax2Checkin[legIdx]?.seatCategoryType !== 'First Class'">
                  <span id="desc-seat-change" *haText="rendering.props['description']"></span>
                </div>
                <div class="seat-message step-desc col-12 col-md-6" *ngIf="isNonRev">
                  <span id="desc-seat-change-nonrev" *haText="rendering.props['descriptionNonRev']"></span>
                </div>
                <div class="col-sm-12 col-md-6 ml-auto seat-btn-div p-md-0">
                  <button id="stanby-seat-upgrade-button" mat-button *ngIf="showEliteStandby"
                    class="btn btn-secondary ml-0 standby-button" (click)="clickStandBySeatUpgrade()">
                    <span *haText="rendering?.props['standbyForSeatUpgrade']"></span>
                  </button>
                  <button id="seats-change-{{legIdx}}" mat-button class="btn btn-default mr-0 float-right"
                    [disabled]="(cartLoading$ | async) || isNonRev" (click)="goToSeatmap(leg?.id)" accesskey="v">
                    <span *haText="rendering.props['viewAndChange']"></span>
                  </button>
                </div>
              </div>
              <div class="row mt-md-5 mt-0" *ngIf="!flightsIsOperatedByHA[legIdx].operatedByHA">
                <div id="oa-seats-notice" class="col-md-12 step-desc oa-seats-notice call-out secondary-text mb-5">
                  {{ flightsIsOperatedByHA[legIdx].operatedByDescription }}
                </div>
              </div>
              <div class="seat-upsell-grid row d-none d-md-flex mt-5">
                <app-seat-upsell-grid class="col-md-12" [rendering]="seatUpsellRendering"
                  [flightId]="leg?.id"></app-seat-upsell-grid>
              </div>
            </div>
          </div>
          <ng-template [cdkPortalOutlet]="isTablet ? seatTout : null"></ng-template>
          <ng-template cdkPortal #seatTout="cdkPortal">
            <div class="seats-itin-tout" *ngIf="isAnyMCB && flightsIsOperatedByHA[legIdx].operatedByHA && !isNonRev">
              <app-seat-tout [rendering]="seatToutRendering" [flightId]="leg?.id"></app-seat-tout>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
</ng-template>
