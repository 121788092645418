import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { RegexPatterns } from '~app/constants/regex-constants';

export class PhoneNumberValidators extends Validators {
  static pattern(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control?.value && control.parent?.get('countryCode')?.value === 'US') {
        const regEx = new RegExp(RegexPatterns.phoneNumberValidation);
        
        return regEx.test(control?.value) ? null : { characterCountError: 'phoneNumberValidation' };
      }
      return null;
    }
  }
}